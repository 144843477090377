<template>
  <div class="plugin-container">
    <div class="plugin-dialog" v-if="dialogSecretShow">
      <div class="dialog-one-box" v-if="dialogSecretShowOne">
        <div class="btns" @click="handleSecret">填写授权码</div>
        <div class="tips">* 激活PRO版本即可使用</div>
      </div>
      <div class="two-box-father" v-if="dialogSecretShowTwo">
        <div class="dialog-two-box">
          <div class="box-left">
            <p class="tips1">联系我们获取授权码</p>
            <img class="qr-code" src="~@/assets/images/er-code.png" />
            <p class="tips2">扫一扫，微信联系我们</p>
            <div class="phone-box">
              <img src="~@/assets/images/phone1.png" />
              <div class="phone-right">
                <p class="tips3">电话咨询</p>
                <p class="tips4">400-800-8888</p>
              </div>
            </div>
          </div>
          <div class="box-right">
            <div class="right-tips">激活PRO版本</div>
            <el-input
              class="right-input"
              placeholder="请输入激活码"
              v-model="cloud_secret"
              maxlength="255"
            >
            </el-input>
            <div class="right-btn">
              <el-button @click="closeSecret">取消</el-button>
              <el-button
                type="primary"
                @click="submitSecret"
                :loading="dialogSecretLoading"
                >提交验证
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="middleground">
      <div class="middleground_title">更多好用系统推荐</div>
      <div class="d-flex align-items-center flex-wrap middleground_list">
        <div
          class="middleSingle"
          v-for="(item, index) in middleList"
          :key="index"
        >
          <div class="middleSingle_head">
            <div class="middleSingle_img">
              <img :src="item.pic_url" alt="" />
            </div>
            <div class="middleSingle_text">
              <div class="middleSingle_text_title">{{ item.display_name }}</div>
              <div class="middleSingle_text_content">{{ item.introduce }}</div>
            </div>
          </div>
          <div class="middleSingle_foot">
            <div class="middleSingle_rate">
              <p>推荐指数</p>
              <el-rate v-model="item.rate" disabled></el-rate>
            </div>
            <div class="middleSingle_but">
              <template v-if="item.status == 'installed'">
                <template v-if="item.name!='supplier'">
                  <el-button @click.stop="settingInstall(item)">设置</el-button>
                </template>
                <template v-if="item.name=='supplier'">
                  <el-button @click.stop="supplierDetail(item)">立即访问</el-button>
                </template>
                <template v-else>
                  <el-button @click.stop="toDetail(item)">立即使用</el-button>
                </template>
                
              </template>
              <template v-else>
                <template v-if="item.status == 'uninstalled'">
                  <el-button
                    type="primary"
                    @click="middleinstall(item)"
                    :loading="item.installLoading"
                  >
                    安装
                  </el-button>
                </template>
                <template v-else-if="item.status == 'not_pay'">
                  <el-button disabled>待开通 </el-button>
                </template>
                <template v-else>
                  <el-button type="primary" @click.stop="openClear(item)"
                    >立即开通</el-button
                  >
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="plugin-list" v-for="(value, key) in pluginList">
      <el-row style="" :gutter="10">
        <!-- 插件标题 -->
        <el-col :span="8">
          <div
            class="plugin-item plugin-itemed"
            :style="'background-image: url(' + value.back_url + ')'"
          >
            <h4>{{ value.title }}</h4>
            <p>{{ value.content }}</p>
          </div>
        </el-col>

        <el-col
          v-for="(item, index) in value.plugin_list"
          :key="index"
          :span="8"
        >
          <div class="plugin-item" @click="showDetail(item)">
            <div flex="box:first">
              <div class="plugin-imgs">
                <div
                  class="plugin-icon"
                  :style="'background-image: url(' + item.pic_url + ')'"
                ></div>
              </div>
              <div>
                <div class="plugin-name d-flex align-items-center">
                  <strong v-text="item.display_name"></strong>
                  <span
                    class="plugin-tag"
                    v-if="item.tag"
                    v-text="item.tag"
                  ></span>
                  <span class="plugin-tag up" v-if="item.new_version"
                    >升级</span
                  >
                </div>

                <div>
                  <div class="plugin-synopsis">
                    <p>{{ item.introduce }}</p>
                  </div>
                  <div v-if="item.installed" class="plugin-installed">
                    <img src="~@/assets/images/plugin-installed.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pluginList: [],
      cloud_secret: "",
      dialogSecretShow: false, // 遮罩层
      dialogSecretShowOne: false, // 一级弹窗
      dialogSecretShowTwo: false, // 二级弹窗
      dialogSecretLoading: false, // 保存loading
      middleList: [],
    };
  },
  async created() {
    // 获取是否已经填写过激活码

    let resource = await this.$get(this.$apis.resource, {
      keys: ["cloud.installed"],
    });
    // console.log(resource, "resource");
    if (resource.code == 200) {
      if (!resource.data["cloud.installed"]) {
        this.dialogSecretShow = true;
        this.dialogSecretShowOne = true;
      }
    }

    // 获取列表
    this.getPluginList();
  },
  methods: {
    getPluginList() {
      this.$get(this.$apis.appPluginList)
        .then((res) => {
          // console.log(res, "appPluginList");
          if (res.code == 200) {
            this.pluginList = res.data.list;
            res.data.platform.forEach((item) => {
              item.rate = 5;
              item.installLoading = false;
            });
            this.middleList = res.data.platform;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    showDetail(item) {
      if (!item.installed || item.new_version) {
        this.$router.push({
          path: "/app/index/detail",
          query: {
            name: item.name,
          },
        });
        // this.$router.push({ path: "/zycg/index"})
      } else {
        this.$router.push(item.route);

        // window.parent.open('http://' + window.location.hostname + '/' + item.route, '_self');
      }
    },
    handleSecret() {
      this.dialogSecretShowOne = false;
      this.dialogSecretShowTwo = true;
    },
    closeSecret() {
      this.dialogSecretShowTwo = false;
      this.dialogSecretShowOne = true;
      this.cloud_secret = "";
    },
    submitSecret() {
      if (!this.cloud_secret) {
        this.$message.error("激活码不能为空");
        return false;
      }
      this.dialogSecretLoading = true;
      this.$post(this.$apis.appPluginSecret, {
        cloud_secret: this.cloud_secret,
      })
        .then((res) => {
          if (res.code == 200) {
            // 如果成功，关闭弹窗 遮罩层
            if (res.data.result) {
              this.$message.success(res.message);
              this.dialogSecretShowTwo = false;
              this.dialogSecretShow = false;
            }
          } else {
            this.$message.error(res.message);
          }
          this.dialogSecretLoading = false;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    openClear(item) {
      let that = this;
      this.$confirm("确认是否要开通该系统？", "提示")
        .then((e) => {
          that.createOrder(item);
        })
        .catch((e) => {});
    },
    createOrder(item) {
      this.buyLoading = true;
      this.$post(this.$apis.appPluginBuy + item.name).then((res) => {
        console.log("appPluginBuy", res);
        if (res.code == 200) {
          this.$message.success("订单提交成功");
          location.reload();
        } else {
          this.buyLoading = false;
          this.$message.error(res.message);
        }
      });
    },
    supplierDetail(item){
      this.$router.push({
        path: item.route,
      })
    },
    // 访问其他系统
    toDetail(item) {
      let that = this;
      this.$get(this.$apis.interviewSystem + item.id)
        .then((res) => {
          if (res.code == 200) {
            window.open(res.data.url);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    // 安装其他系统
    middleinstall(item) {
      this.$confirm('确认是否要安装此系统？', '提示').then(e => {
                this.doInstall(item);
            }).catch(e => {
            });
    },
    doInstall(item) {
      item.installLoading = true;
      let stopContent = "系统安装中，请勿关闭或刷新页面！";
      window.onbeforeunload = function (event) {
        event.returnValue = stopContent;
      };
      if (parent && parent.stopPageClose) {
        parent.stopPageClose(true, stopContent);
      }

      this.$post(this.$apis.appPluginInstall + item.name).then((res) => {
        console.log("appPluginInstall", res);
        window.onbeforeunload = null;
        if (parent && parent.stopPageClose) {
          parent.stopPageClose(false);
        }
        if (res.code == 200) {
          this.$message.success(res.message);
          location.reload();
        } else {
          item.installLoading = false;
          this.$message.error(res.message);
        }
      });
    },
    settingInstall(item) {
      this.$router.push({
        path: "/app/index/more",
        query: {
          id: item.id,
          name: item.display_name,
          site: item.name,
        },
      });
    },
  },
};
</script>


<style scoped lang="scss">
@import "./flex.css";

.plugin-dialog {
  position: fixed;
  z-index: 9;
  background-color: rgba($color: #000000, $alpha: 0.6);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;

  .dialog-one-box {
    width: calc(100% - 239px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btns {
      width: 116px;
      height: 40px;
      border-radius: 4px;
      background: linear-gradient(90deg, #030f9f 0%, #3444ff 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .tips {
      color: #ffffff;
      font-size: 16px;
      margin-top: 16px;
    }
  }

  .two-box-father {
    width: calc(100% - 239px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dialog-two-box {
    width: 672px;
    height: 363px;
    display: flex;
    border-radius: 3px;
    background-color: #fff;

    .box-left {
      width: 268px;
      height: 100%;
      background-color: #1e2cd4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .tips1 {
        color: rgba($color: #fff, $alpha: 0.9);
        margin-top: 28px;
        font-size: 16px;
      }

      .qr-code {
        width: 120px;
        height: 120px;
        margin-top: 38px;
      }

      .tips2 {
        color: rgba($color: #fff, $alpha: 0.9);
        margin-top: 10px;
        font-size: 14px;
      }

      .phone-box {
        display: flex;
        align-items: center;
        margin-top: 42px;

        > img {
          width: 33px;
          height: 33px;
          margin-right: 8px;
        }

        .phone-right {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;

          .tips3 {
            color: rgba($color: #fff, $alpha: 0.9);
            font-size: 13px;
          }

          .tips4 {
            color: rgba($color: #fff, $alpha: 0.9);
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }

    .box-right {
      position: relative;
      width: 404px;
      height: 100%;
      box-sizing: border-box;
      padding: 28px 32px;

      .right-tips {
        color: rgba($color: #000000, $alpha: 0.9);
        font-weight: 600;
        font-size: 16px;
      }

      .right-input {
        margin-top: 50px;
      }

      .right-btn {
        position: absolute;
        right: 32px;
        bottom: 28px;
      }
    }
  }
}
.middleground {
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 32px;
  .middleground_title {
    padding-top: 24px;
    margin-left: 24px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
    margin-bottom: 16px;
  }
  .middleground_list {
    width: 100%;
    padding: 0 24px;
    padding-bottom: 16px;
    box-sizing: border-box;
    .middleSingle {
      background: linear-gradient(180deg, #f0f7ff 0%, #ffffff 100%);
      box-shadow: 0px 8px 20px rgba(184, 199, 230, 0.14);
      border-radius: 7px;
      border: 1px solid #eaeef5;
      flex: 1;
      width: 32.5%;
      margin-right: 1.25%;
      margin-bottom: 16px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .middleSingle_head {
        margin: 0 20px;
        margin-top: 25px;
        display: flex;
        height: 125px;
        @media screen and (max-width: 1650px) {
          & {
            height: 170px;
          }
        }
        .middleSingle_img {
          width: 80px;
          height: 80px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .middleSingle_text {
          margin-left: 10px;
          flex: 1;
          .middleSingle_text_title {
            font-size: 16px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.9);
            line-height: 22px;
          }
          .middleSingle_text_content {
            font-size: 14px;
            font-weight: 400;
            color: #b3b3b3;
            line-height: 20px;
            margin-top: 14px;
          }
        }
      }
      .middleSingle_foot {
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        background: #fafcfe;
        border-radius: 0px 0px 7px 7px;
        border: 1px solid #eaeef5;
        .middleSingle_rate {
          p {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
          }
          /deep/.el-rate {
            .el-rate__icon {
              margin-right: 0;
            }
          }
        }
        .middleSingle_but {
        }
      }
    }
  }
}

.plugin-list {
  padding: 0 0 20px 0;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
}

.plugin-item {
  background-color: #fff;
  padding: 28px 16px;
  height: 106px;
  position: relative;
  overflow: hidden;
  margin-right: 0;
  margin-bottom: 10px;
  /* box-shadow: 0 0 1px rgba(0, 0, 0, .15); */
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  box-sizing: border-box;
}

.plugin-icon {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  margin-right: 15px;
}

.plugin-name {
  /* font-size: 18px; */

  margin-bottom: 11.5px;
  color: #333;
  line-height: 1.5;
}

.plugin-price {
  color: #f56c6c;
  margin-bottom: 12px;
  font-weight: bold;
}

.plugin-desc {
  font-size: 12px;
  color: #909399;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* .plugin-tag {
    position: absolute;
    right: calc(-50% + 10px);
    top: -5px;
    background: #ff4544;
    color: #fff;
    padding: 0 0;
    width: 100%;
    height: 35px;
    line-height: 45px;
    text-align: center;
    transform: rotate(45deg);
    font-size: 10px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .15);
} */
/* 新增 */
.plugin-list:last-child {
  margin-bottom: 0;
}

.plugin-imgs {
  padding-top: 4px;
  box-sizing: border-box;
}

.plugin-name strong {
  display: block;
  font-size: 17px;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.plugin-name span {
  display: table;
  font-size: 12px;
  color: #f22727;
  flex-shrink: 0;
  font-weight: 400;
  line-height: 12px;
  padding: 2px 8px;
  max-height: 16px;
  box-sizing: border-box;
  background: #ffe5e5;
  border-radius: 3px;
  vertical-align: 1px;
  margin-left: 5px;
}

.plugin-name span.up {
  color: #2433e0;
  background: #e2e4ff;
}

.plugin-itemed {
  padding: 26px 32px;
  box-sizing: border-box;
  cursor: default;
}

.plugin-itemed h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plugin-itemed p {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  line-height: 14px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plugin-synopsis p {
  font-size: 14px;
  color: #636669;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  line-height: 16px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.plugin-installed {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
}

.plugin-installed img {
  display: block;
}

.plugin-item {
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}

.plugin-item:hover {
  -webkit-box-shadow: 0px 1px 14px rgba(133, 148, 162, 0.2);
  box-shadow: 0px 1px 14px rgba(133, 148, 162, 0.2);
  -webkit-transform: translatey(-2px);
  transform: translatey(-2px);
}

.plugin-itemed:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform: translatey(0px);
  transform: translatey(0px);
}

.plugin-item:hover .plugin-name strong {
  color: #5079f3;
}
</style>
